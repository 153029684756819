import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import dayjs from 'dayjs'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { FeatureThumbnail } from './EditorialThumbnail'

import ArrowRightSVG from '../../../assets/svg/arrow-right.svg'

const humanDate = (date) => dayjs(date).format('DD MMM YYYY')

const EventsFeatureArticleLink = ({ article }) => {
  const image = article.thumbnailImage ?? article.heroImage

  return (
    <div className="c-editorialItem c-editorialItem--fullWidth">
      <div className="c-editorialItem__header">
        <span className="c-editorialItem__category text-uppercase">
          <Link to={article.categoryLink}>{article.category}</Link>
        </span>
        <span className="c-editorialItem__date">
          <time dateTime={article.eventDate}>
            {humanDate(article.eventDate)}
          </time>
        </span>
      </div>
      <div className="c-articleThumbnail c-editorialItem__thumbnail">
        <div className="c-eventHeroOverlay">
          <div className="c-eventHeroOverlay__header">
            <time dateTime={article.eventDate}>
              Event Date: {''}
              {humanDate(article.eventDate)}
            </time>
          </div>
          <Link to={article.relPath}>
            <h3 className="c-eventHeroOverlay__title">{article.title}</h3>
          </Link>
          <p className="c-eventHeroOverlay__excerpt">{article.excerpt}</p>
          <Link
            to={article.relPath}
            className="c-button c-button--secondary"
            title={'Read ' + article.title}
          >
            <ArrowRightSVG className="c-icon mr-2" />
            Read
          </Link>
        </div>
        <FeatureThumbnail image={image} />
      </div>
      <div className="c-editorialItem__links">
        <h3 className="c-editorialItem__title">
          <Link to={article.relPath}>{article.title}</Link>
        </h3>
        <Link
          to={article.relPath}
          className="c-button c-button--secondary"
          title={'Read ' + article.title}
        >
          <ArrowRightSVG className="c-icon mr-2" />
          Read
        </Link>
      </div>
    </div>
  )
}

EventsFeatureArticleLink.propTypes = {
  article: PropTypes.object.isRequired,
}

export default EventsFeatureArticleLink
