import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import ArrowLeftSVG from '../../../assets/svg/arrow-left.svg'
import ArrowRightSVG from '../../../assets/svg/arrow-right.svg'

const Pagination = ({ totalPages, currentPage, baseUri }) => {
  const pageList = [...Array(totalPages).keys()]
  let ellipsed = false
  let shouldEllipse = false

  const prevTo = () => {
    let prevNum = currentPage - 1
    return prevNum > 1 ? `${baseUri}/page/${prevNum}` : `${baseUri}`
  }

  const nextTo = () => {
    let nextPage = totalPages === currentPage ? currentPage : currentPage + 1
    return `${baseUri}/page/${nextPage}`
  }

  return (
    <ul className="c-pagination">
      <li
        className={`c-pagination__item c-pagination__item--prev ${
          currentPage === 1 && 'c-pagination__item--disabled'
        }`}
      >
        <Link
          to={prevTo()}
          className="c-pagination__link"
          aria-label="Previous"
        >
          <span aria-hidden="true">
            <ArrowLeftSVG className="c-icon mr-3" />
            Previous
          </span>
        </Link>
      </li>
      <li className="c-pagination__item c-pagination__item--text d-none d-md-block">
        <span aria-hidden="true">Page</span>
      </li>
      {pageList.map((pageIndex) => {
        let pageNumber = pageIndex + 1
        let right = totalPages - pageNumber
        let showNumber = pageNumber <= 3 || right === 0
        showNumber =
          showNumber ||
          (pageNumber > currentPage - 2 && pageNumber < currentPage + 2)

        if (showNumber) {
          ellipsed = false
          shouldEllipse = false
        } else {
          shouldEllipse = !ellipsed
          ellipsed = true
        }

        return (
          <Fragment key={`page_${pageNumber}`}>
            {showNumber && (
              <li
                className={`c-pagination__item ${
                  currentPage === pageNumber && 'c-pagination__item--active'
                }`}
              >
                <Link
                  to={
                    pageNumber === 1
                      ? `${baseUri}`
                      : `${baseUri}/page/${pageNumber}`
                  }
                  className="c-pagination__link"
                >
                  {pageNumber}
                </Link>
              </li>
            )}
            {shouldEllipse && (
              <li className="c-pagination__item c-pagination__item--text">
                <span aria-hidden="true">&nbsp;&hellip;&nbsp;</span>
              </li>
            )}
          </Fragment>
        )
      })}
      <li
        className={`c-pagination__item c-pagination__item--next ${
          currentPage === totalPages && 'c-pagination__item--disabled'
        }`}
      >
        <Link to={nextTo()} className="c-pagination__link" aria-label="Next">
          <span aria-hidden="true">
            <ArrowRightSVG className="c-icon mr-3" />
            Next
          </span>
        </Link>
      </li>
    </ul>
  )
}

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  baseUri: PropTypes.string.isRequired,
}

export default Pagination
