import React, { Component } from 'react'
import { Link } from 'gatsby'

import Page from '../../components/Page'
import SEO from '../../components/SEO'
import Schema from '../../components/Schema'
import EventsArticleLink from '../../components/articles/Listings/EventsArticleLink'
import EventsFeatureArticleLink from '../../components/articles/Listings/EventsFeatureArticleLink'
import Pagination from '../../components/articles/Listings/Pagination'
import Newsletter from '../../components/Newsletter'

const renderArticleLink = (article, i, currentPage, feature) => {
  if (currentPage === 1) {
    if (i === 0) {
      return (
        <div key={article.slug} className="col-12">
          <EventsFeatureArticleLink article={article} />
        </div>
      )
    } else if ([1].includes(i)) {
      return (
        <div key={article.slug} className="col-6 col-lg-8">
          <EventsArticleLink size={`lg`} article={article} />
        </div>
      )
    }
  }

  return (
    <div key={article.slug} className="col-6 col-lg-4">
      <EventsArticleLink size={`md`} article={article} />
    </div>
  )
}

export default class ListTemplate extends Component {
  render() {
    const {
      articles,
      totalPages,
      currentPage,
      baseUri,
      feature,
    } = this.props.pageContext

    let seoTitle = 'Erudus Events'

    const breadcrumbs = [
      {
        name: 'Events',
        uri: '/events',
      },
    ]

    return (
      <Page className="p-posts p-posts--events has-fingerPrints">
        <SEO title={seoTitle} />
        <Schema breadcrumbs={breadcrumbs} />

        <section id="news" className="c-postsHeader c-postsHeader--events">
          <div className="container">
            <div className="c-postsHeader__inner row">
              <div className="c-postsHeader__title col-md-2">
                <h1>Events</h1>
              </div>
              <div className="c-postsHeader__text col-md-10">
                The <strong>Erudus dates</strong> for your diary you won’t want
                to miss…
              </div>
            </div>
          </div>
        </section>

        <section id="latest-editorial" className="c-latestEvent">
          <div className="container">
            <div className="text-right py-4">
              <Link to="/search" className="c-postReturnLink">
                {/* <img src="/icons/arrow-right.svg" className="c-icon mr-2" /> */}
                <span className="c-postReturnLink__text">Search</span>
              </Link>
            </div>
            <div className="c-latestEvent__inner pt-2">
              <div className="c-latestEvent__items row">
                {articles.map((article, i) =>
                  renderArticleLink(article, i, currentPage, feature)
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="c-section bg-white">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                {totalPages > 1 && (
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    baseUri={baseUri}
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <Newsletter />
      </Page>
    )
  }
}
