import React from 'react'
import PropTypes from 'prop-types'
import useWindowSize from '../../../hooks/useWindowSize'
import { baseImageSrc, backgroundStyle } from '../../../utils/Thumbnail'

const NewsThumbnail = ({ size, image }) => {
  if (size === 'lg') return <LargeThumbnail image={image} />
  return <MediumThumbnail image={image} />
}

NewsThumbnail.defaultProps = {
  size: 'md',
}

NewsThumbnail.propTypes = {
  image: PropTypes.object.isRequired,
  size: PropTypes.string,
}

export default NewsThumbnail

const MediumThumbnail = ({ image }) => {
  const src = baseImageSrc(image)
  const { width } = useWindowSize()
  const style = backgroundStyle(image)

  return (
    <div className="c-articleThumbnail c-editorialItem__thumbnail">
      <picture>
        <source
          srcSet={`${src}&w=350&h=400&dpr=1 1x, ${src}&w=350&h=400&dpr=2 2x`}
          media="(min-width: 768px)"
        />
        <source
          srcSet={`${src}&fp-z=1.5&w=250&h=250&dpr=1 1x, ${src}&fp-z=1.5&w=250&h=250&dpr=2 2x`}
          media="(min-width: 576px)"
        />
        <source
          srcSet={`${src}&w=580&h=500&dpr=1 1x, ${src}&w=580&h=500&dpr=2 2x`}
          media="(min-width: 400px)"
        />
        <img
          className="c-articleThumbnail__image img-fluid"
          srcSet={`${src}&w=400&h=400&dpr=1 1x, ${src}&w=400&h=400&dpr=2 2x`}
          src={`${src}&w=400&h=400`}
          title={image.title}
          alt={image.alt}
          width={
            width > 767
              ? `350`
              : width > 575
              ? `250`
              : width > 399
              ? `580`
              : `400`
          }
          height={
            width > 767
              ? `400`
              : width > 575
              ? `250`
              : width > 399
              ? `500`
              : `400`
          }
          loading="lazy"
          style={style}
        />
      </picture>
    </div>
  )
}

const LargeThumbnail = ({ image }) => {
  const src = baseImageSrc(image)
  const { width } = useWindowSize()
  const style = backgroundStyle(image)

  return (
    <div className="c-articleThumbnail c-editorialItem__thumbnail">
      <picture>
        <source
          srcSet={`${src}&w=730&h=400&dpr=1 1x, ${src}&w=730&h=400&dpr=2 2x`}
          media="(min-width: 1200px)"
        />
        <source
          srcSet={`${src}&w=735&h=400&dpr=1 1x, ${src}&w=735&h=400&dpr=2 2x`}
          media="(min-width: 992px)"
        />
        <source
          srcSet={`${src}&w=350&h=400&dpr=1 1x, ${src}&w=350&h=400&dpr=2 2x`}
          media="(min-width: 768px)"
        />
        <source
          srcSet={`${src}&fp-z=1.5&w=250&h=250&dpr=1 1x, ${src}&fp-z=1.5&w=250&h=250&dpr=2 2x`}
          media="(min-width: 576px)"
        />
        <source
          srcSet={`${src}&w=580&h=500&dpr=1 1x, ${src}&w=580&h=500&dpr=2 2x`}
          media="(min-width: 400px)"
        />
        <img
          className="c-articleThumbnail__image img-fluid"
          srcSet={`${src}&w=400&h=400&dpr=1 1x, ${src}&w=400&h=400&dpr=2 2x`}
          src={`${src}&w=400&h=400`}
          title={image.title}
          alt={image.alt}
          width={
            width > 1199
              ? `730`
              : width > 991
              ? `735`
              : width > 767
              ? `350`
              : width > 575
              ? `250`
              : width > 399
              ? `580`
              : `400`
          }
          height={
            width > 767
              ? `400`
              : width > 575
              ? `250`
              : width > 399
              ? `500`
              : `400`
          }
          loading="lazy"
          style={style}
        />
      </picture>
    </div>
  )
}

export const FeatureThumbnail = ({ image }) => {
  const src = baseImageSrc(image)
  const { width } = useWindowSize()
  const style = backgroundStyle(image)

  return (
    <picture>
      <source
        srcSet={`${src}&w=730&h=350&dpr=1 1x, ${src}&w=730&h=350&dpr=2 2x`}
        media="(min-width: 992px)"
      />
      <source
        srcSet={`${src}&fp-z=1.5&w=768&h=500&dpr=1 1x, ${src}&fp-z=1.5&w=768&h=500&dpr=2 2x`}
        media="(min-width: 580px)"
      />
      <source
        srcSet={`${src}&w=580&h=500&dpr=1 1x, ${src}&w=580&h=500&dpr=2 2x`}
        media="(min-width: 450px)"
      />
      <img
        className="c-articleThumbnail__image img-fluid"
        srcSet={`${src}&w=400&h=375&dpr=1 1x, ${src}&w=400&h=375&dpr=2 2x`}
        src={`${src}&w=400&h=400`}
        title={image.title}
        alt={image.alt}
        width={
          width > 991
            ? `730`
            : width > 579
            ? `768`
            : width > 449
            ? `580`
            : `400`
        }
        height={width > 991 ? `350` : width > 449 ? `500` : `375`}
        loading="lazy"
        style={style}
      />
    </picture>
  )
}
