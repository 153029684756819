import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import dayjs from 'dayjs'

import Thumbnail from './EditorialThumbnail'

import ArrowRightSVG from '../../../assets/svg/arrow-right.svg'

const humanDate = (date) => dayjs(date).format('DD MMM YYYY')

const EventsArticleLink = ({ article, size }) => {
  return (
    <div className="c-eventItem">
      <div className="c-eventItem__header">
        <span className="c-eventItem__date">
          Event Date: {''}
          <time dateTime={article.eventDate}>
            {humanDate(article.eventDate)}
          </time>
        </span>
      </div>
      <Link to={article.relPath}>
        <Thumbnail
          image={article.thumbnailImage ?? article.heroImage}
          size={size}
        />
      </Link>
      <h3 className="c-eventItem__title pt-3">
        <Link to={article.relPath}>{article.title}</Link>
      </h3>
      <Link
        to={article.relPath}
        className="c-button c-button--secondary"
        title={'Read ' + article.title}
      >
        <ArrowRightSVG className="c-icon mr-2" />
        Read
      </Link>
    </div>
  )
}

EventsArticleLink.propTypes = {
  article: PropTypes.object.isRequired,
}

export default EventsArticleLink
